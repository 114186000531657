import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { IdeaPageComponent } from './idea-page/idea-page.component';
import { AddNewIdeaComponent } from './add-new-idea/add-new-idea.component';
import { ListIdeaComponent } from './list-idea/list-idea.component';
import { IdeaDetailComponent } from './idea-detail/idea-detail.component';
import { InnovationService } from '../../shared/services/innovation.service'

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { breadcrumb: null },
    children: [
      {
        path: 'idea-page',
        component: IdeaPageComponent,
      },
      {
        path: 'add-new-idea',
        component: AddNewIdeaComponent,
      },
      {
        path: 'add-new-idea/:id',
        component: AddNewIdeaComponent,
      },
      {
        path: 'detail-ideas/:id',
        component: IdeaDetailComponent,
      },
      {
        path: 'list-idea', data: { breadcrumb: 'Ideas' },
        component: ListIdeaComponent,
      },

      {
        path: 'list-idea/:id',
        //data: { breadcrumb: 'Ideas' } ,
        data: { breadcrumb: (data: any) => data?.idea?.records[0]?.status?.label ? data?.idea?.records[0]?.status?.label + " Ideas" : 'Ideas' },
        resolve: { idea: InnovationService },
        component: ListIdeaComponent,
      }

    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InnovationRoutingModule { }
