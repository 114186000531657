import { Component, OnInit } from '@angular/core';
import { ProgramService } from 'src/app/shared/services/program.service';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import {  Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { InnovationService } from '../../../shared/services/innovation.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { DatePipe } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-list-idea',
  templateUrl: './list-idea.component.html',
  styleUrls: ['./list-idea.component.scss'],
  providers:[DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class ListIdeaComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  proposalLists: any[] = [];
  proposalListsTotal:any=[];
  limit: any;
  offset: any;
  totalCount: any;
  searchText = '';
  search = false;
  filterForm: FormGroup | any;
  filterOptionPortfolio1: Observable<any[]> | any;
  filterOptionSubPortfolio1: Observable<any[]> | any;
  portList: any[] = [];
  portFolioId: any;
  subPortfilteredOptions: any[] = [];
  program: any;
  subPortId: any;
  page: number = 1;
  endVal: number = 12;
  startVal: number = 0;
  itemsPerPage: number = 12;
  subPortChildOptions: any[] = [];
  OpportunityFieldList: any;
  disableShowMore: boolean = false;
  scree_name = 'Innovation';
  benefitsData: any;
  initiativeData: any;
  ideaStatus: any;
  statusId: any;
  initativeId: any;
  benefitsId: any;
  vendor: any;

  constructor(private proposalService: GetProposalService,
    private toastrService: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private ps: ProgramService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private csvService: DataToCsvService,private datePipe:DatePipe,
    private innovationService: InnovationService) { }

  ngOnInit(): void {
    this.limit = 10;
    this.offset = 0;
    this.getOpportunity();
    //this.getProgram();

    this.createForm();
    this.getIdeaStatus();
    this.getInitiativeType();
    this.getBenefitsData();
    this.getStatusId();
    this.getDataSource();

  }

  getStatusId() {
    this.activatedRoute.params.subscribe(params => {
      this.statusId = params.id ? atob(params.id) :params.id;
      if (this.statusId != '') {
        this.statusChange(this.statusId)
      }
    });
  }


  getDataSource(isTotal:boolean =false) {
    this.spinner.show();
    let endVal:any = isTotal ? this.totalCount : this.endVal;
    this.innovationService.getInnovation(this.startVal, endVal, this.subPortId, this.searchText, this.portFolioId, this.initativeId, this.benefitsId, this.statusId, this.vendor).subscribe((res: any) => {
      this.spinner.hide();
      this.totalCount = res.totalRecords;
      if(isTotal){
        this.proposalListsTotal = res.records;
        this.saveAsCSV();
      }else{
        this.proposalLists = res.records;
      }
      this.spinner.hide();
    }, (error: any) => { }, () => {
      this.spinner.hide();
    }
    )
  }

  getOpportunity() {
    this.ps.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList = res.records;
      this.OpportunityFieldList.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.portList = i.field_values;

        }
      })
      this.createForm();
    }, error => {
      // this.toastrService.error('Opportunity fields get failed', 'Error');
      throw error;
    })
  }
  clearFilter() {
    this.searchText = '';
    this.portFolioId = 0;
    this.subPortId = 0;
    //this.program = 0;
    this.statusId = 0;
    this.benefitsId = 0;
    this.initativeId = 0;
    this.initiativeData
    this.filterForm.patchValue({
      portfolio: '',
      subPortfolio: '',
    })
    this.getDataSource();
  }

  deleteProposal(id: any) {
    this.proposalService.deleteProposal(id).subscribe((res: any) => {
      this.getDataSource();
      this.toastrService.success('Deleted Successfully');
    }, (error: any) => {
      this.toastrService.error('Error');
      throw error;
    })
  }
  editProposal(id: any) {
    this.router.navigate([`rfx/`+btoa(id)+`/opportunity/setup`]);
  }

  createForm() {
    this.filterForm = this.fb.group({
      portfolio: [''],
      subPortfolio: [''],
    });

    this.filterOptionPortfolio1 = this.filterForm.get('portfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((display_name: any) => display_name ? this.typeFilter(display_name) : this.portList.slice())
      );
  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.portList.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }

  validateData(type: any) {
  }
  selectedPortfolioValues(id: any) {
    this.portFolioId = id;
    this.subPortfilteredOptions = [];
    this.subPortId = "";
    // if (!this.portFolioId) {
    //   return;
    // }
    this.filterForm.patchValue({
      subPortfolio: ''
    })
    this.getDataSource();
    this.subPortfilteredOptions = [];
    this.filterOptionSubPortfolio1 = this.filterForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.display_name),
        map((display_name: any) => display_name ? this.subPortfolioFilter(display_name) : this.subPortfilteredOptions.slice())
      );
  }

  selectedSubportfolioValues(id: any) {
    this.subPortId = id;
    this.getDataSource();
  }

  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  changeSubPortfolio(e: any) {
    if (e.isUserInput) {
      this.subPortId = e.source.value.id;
      this.getDataSource();
    }
  }

  viewProposal(id: any) {
    this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`])
  }

  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page - 1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getDataSource();
  }

  searchName() {
    this.getDataSource();
  }
  onInputChange(e: any) {
    if (this.searchText == '') {
      this.getDataSource();
    }
  }

  callRefereshParentData(e: any) {
    this.getDataSource()
  }
  showAllProposals() {
    this.startVal = 0;
    this.endVal = this.proposalLists?.length + (this.itemsPerPage);
    this.getDataSource();
    if (this.totalCount < this.endVal) {
      this.disableShowMore = true;
    }
  }

  getStatus(status: string) {
    return 'red';
  }

  IdeaPage() {
    this.router.navigate(['innovation/add-new-idea'])
  }

  statusChange(e: any) {
    this.statusId = e;
    this.getDataSource();
    this.showAllProposals();
  }

  getIdeaStatus() {
    this.innovationService.getStatusFieldValues().subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.ideaStatus = res;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
    this.spinner.show();
  }

  getInitiativeType() {
    this.innovationService.getInitiativeData().subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.initiativeData = res;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
    this.spinner.show();
  }

  getBenefitsData() {
    this.innovationService.getBenefitsFieldValue().subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.benefitsData = res;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
    this.spinner.show();
  }

  selectedInitiative(id: any) {
    this.initativeId = id;
    this.getDataSource();
  }

  selectedBenefits(id: any) {
    this.benefitsId = id;
    this.getDataSource();
  }
  
  saveAsCSV() {
    if(this.proposalListsTotal && this.proposalListsTotal.length > 0){
      const items:any = [];
      this.proposalListsTotal.forEach((item:any) => {
        let csvData = {
          "Username": item?.vendor_details == false ?  ('Delta ' + (item?.user_details || '')) :(item?.vendor_details?.primary_contact_details?.username || '') ,
          "Idea Name": item.name,
          "Benefit Name": item.benefits_details?.display_name,
          "Initiative Type": item?.initiative_type_details?.display_name,
          "Portfolio Name": item?.portfolio_details?.[0]?.display_name,
          "Sub Portfolio Name": item?.portfolio_details?.[1]?.display_name,
          "Created on" :this.datePipe.transform(item?.created_on,'dd MMM yyyy'),          
          "Updated on" :this.datePipe.transform(item?.last_updated_on,'dd MMM yyyy'),
          "Project Status":item.status?.label
        }
        items.push(csvData); 
      });
      this.csvService.exportToCsv('Idea_List.CSV', items);
    }else{
      this.toastrService.error('No Records to download');
    }
  }
}