<div class="container-fluid proposal container-xxl">
    <div class="custom-height" id="custom-height">
        <div class="row">
            <div class="col-md-12">
                <div class="screen-style">
                    <div class="row ">
                        <div class="col-md-7">
                            <div class="pt-4 mb-4">
                                <app-bread-crumb></app-bread-crumb>
                                <!-- <small class="text-muted">Dashboard > Continuous Improvement</small> -->
                                <h2 class="screen-name mt-1">{{staticText?.menu.subTitleMenu10}}</h2>
                            </div>
                        </div>
                        <div class="col-md-5 pr-1">
                            <div class="d-flex justify-content-end  pt-4">
                                <button class="float-end border primary lightSecondary add-idea-btn ml-3" mat-button
                                    (click)="IdeaPage()" mat-flat-button>
                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                        class="cursor-pointer fs-5">add
                                    </mat-icon>
                                    {{staticText?.innovation.idea_list.add_idea}}
                                </button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="container-fluid p-0">
                                <app-tabmenu [tabcontentdynamic]="portList" [subportfolio]="subPortfilteredOptions"
                                    [status]="ideaStatus" [benefits]="benefitsData" [initiative]="initiativeData"
                                    (portfolio)="selectedPortfolioValues($event)" (statusValue)="statusChange($event)"
                                    (subportfolioId)="selectedSubportfolioValues($event)"
                                    (BenefitsId)="selectedBenefits($event)" (InitiativeId)="selectedInitiative($event)"
                                    (clearFilterData)="clearFilter()"></app-tabmenu>
                            </div>
                        </div>
                        <div class="col-md-12 p-0">
                            <div class="container-fluid px-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="count text-dark pt-3">{{staticText?.innovation.idea_list.total_ideas}} ({{totalCount}})</p>
                                    </div>
                                    <button mat-button class="float-end border primary lightSecondary download-btn ml-3"  *ngIf="proposalLists && proposalLists?.length>0"
                                    data-toggle="tooltip" data-placement="top" title="Export as CSV" (click)="getDataSource(true)" mat-flat-button>Download</button>
                                </div>
                            </div>
                            <div>
                                <app-table-card [proposalLists]="proposalLists" [screen_name]="scree_name"
                                    (refereshParentData)="callRefereshParentData($event)"></app-table-card>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex">
                        <div class="col-md-10" *ngIf="!disableShowMore && totalCount > 12" class="show-more-wrapper">
                            <a class="show-more fw-bold" (click)="showAllProposals()">{{staticText?.common.show_more}}<i
                                    class="fas fa-angle-double-down fw-normal m-1"></i></a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>