<div class="container-fluid dashboard">
    <div class="row header">
        <div class="bg-cont-overlay">
            <div class="container-fluid container-xxl p-0">
                <div class="row">
                    <div class="col">
                        <h5 class="text-white">{{staticText?.innovation.idea_page.title}}</h5>
                    </div>
                    <!-- <div class="col">
                        <h5 class="text-right color-torquise">Welcome {{user.full_name}}!</h5>
                    </div> -->
                </div>
                <div class="container-fluid container-xxl centerpanel">
                    <div class="comingSoon">
                        <div>
                            <h6>Coming Soon</h6>
                            <img src="assets/Icons/coming-soon.png" width="65px" style="display: block; margin: auto; margin-bottom: 10px;">
                            Our team is hard at work to bring you an exciting new feature!
                        </div>
                    </div> 

                </div>
                <!-- <div class="section-1">
                    <div class="container-fluid container-xxl centerpanel">
                        <div class="custom-height" id="custom-height">
                            <div class="row">
                                <div class="col-md-2">
                                    <span class="bubble-top"><span class="filter-label">{{staticText?.innovation.idea_page.dashboard}}</span></span>
                                </div>
                                <div class="col-md-10">
                                    <div class="d-flex justify-content-end pt-3">
                                        <button class="float-end border primary lightSecondary list-Page ml-3"
                                            (click)="navigateTo('innovation/list-idea')" mat-button mat-flat-button>
                                            {{staticText?.innovation.idea_page.browse_ideas}}
                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                class="cursor-pointer fs-5">chevron_right
                                            </mat-icon>
                                        </button>

                                        <button class="float-end border primary lightSecondary add-idea-btn ml-3"
                                            mat-button (click)="addNewIdea()" mat-flat-button>
                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                class="cursor-pointer fs-5"> add
                                            </mat-icon>
                                            {{staticText?.innovation.add_idea.new_idea}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 p-0">
                                <div class="container-fluid p-0 my-3">
                                    <app-tabmenu [tabcontentdynamic]="portList" [subportfolio]="subPortfilteredOptions"
                                         [benefits]="benefitsData" [initiative]="initiativeData"
                                        (portfolio)="selectedPortfolioValues($event)"
                                        (subportfolioId)="selectedSubportfolioValues($event)"
                                        (BenefitsId)="selectedBenefits($event)"
                                        (InitiativeId)="selectedInitiative($event)" (clearFilterData)="clearFilter()">
                                    </app-tabmenu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex section-2">
                    <div class="col-md-9 p-0 centerpanel">
                        <div class="dashboard-items mb-3">
                            <div class="d-flex justify-content-between align-items-center status-cards">
                                <div class="d-flex">
                                    <div class="dashboard-count-cards">
                                        <span class="cards-bubble-top">&nbsp;</span>
                                    </div>
                                    <h5 class="fw-bold ml-2 mb-0 mt-1 p-3 pb-3">{{staticText?.innovation.idea_page.ideas_benefits}}</h5>
                                </div>
                                <div class="d-flex status-count px-3">
                                    <small class="border-approved cursor-pointer"
                                        (click)="navigateToList(dashboardItems?.status[0].id)"><span
                                            class="open"></span>{{staticText?.status.approved}} <span
                                            class="open-status-count mr-3">{{dashboardItems?.status[0]?.total_count}}</span></small>
                                    <small class="border-open cursor-pointer"
                                        (click)="navigateToList(dashboardItems?.status[1].id)"><span
                                            class="open"></span>{{staticText?.status.open}} <span
                                            class="open-status-count mr-3">{{dashboardItems?.status[1]?.total_count}}</span></small>
                                    <small class="border-closed cursor-pointer"
                                        (click)="navigateToList(dashboardItems?.status[2].id)"><span
                                            class="closed"></span>{{staticText?.status.closed}} <span
                                            class="closed-status-count mr-3">{{dashboardItems?.status[2]?.total_count}}</span></small>
                                    <small class="border-review cursor-pointer"
                                        (click)="navigateToList(dashboardItems?.status[3].id)"><span
                                            class="count-review cursor-pointer"></span>{{staticText?.status.under_review}}
                                        <span
                                            class="review-status-count">{{dashboardItems?.status[3]?.total_count}}</span>
                                    </small>
                                </div>
                            </div>
                            <div class="inner-scroll">
                                <div class="row mx-2">
                                    <div class="col cards-style indicators cursor-pointer"
                                        *ngFor="let data of dashboardItems?.benefits; let i=index"
                                        (click)="showTopIdeas(data.id, data?.display_name)"
                                        [ngClass]="{'active-benefits': benefitsOnSelect == data.id}"
                                        [ngStyle]="{'backgroundColor': i|getRandomColor:'idea'}">

                                        <div class="dashboard-count-cards">
                                            <span class="cards-bubble-top "
                                                [ngStyle]="{'backgroundColor': i|getRandomColor:'bubble_benefits'}">
                                                &nbsp;
                                            </span>
                                        </div>
                                        <div class="mt-3">
                                            <p class="mb-0">{{data?.display_name}}</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mt-2">
                                            <div>
                                                <h4 class="cost">
                                                    {{data?.total_count}}</h4>
                                            </div>
                                            <div>
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">chevron_right
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 ">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="approved-Items mb-3">
                            <div class="dashboard-count-cards">
                                <span class="cards-bubble-top ml-4">&nbsp;</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center p-3 status-cards">
                                <div class="d-flex">
                                    <h5 class="fw-bold  ml-2 mt-2">{{staticText?.innovation.idea_page.ideas_initiative_type}}</h5>
                                </div>
                            </div>
                            <div class=" approved-ideas">
                                <div class="row m-0 px-2 pt-2 pb-2">
                                    <div class="col cards-style indicators cursor-pointer"
                                        *ngFor="let data of initiativeTypes; let i = index;"
                                        [ngClass]="{'active-initiative': initiativeOnSelect == data.id}">
                                        <div class="dashboard-count-cards">
                                            <span class="cards-bubble-top bg-1"
                                                [ngStyle]="{'backgroundColor': i|getRandomColor:'bubble'}">
                                                &nbsp;
                                            </span>
                                        </div>
                                        <div class="d-flex mt-3" (click)="ideasByInitiative(data.id)">
                                            <img src="./assets/images/people.svg"
                                                *ngIf="data.name == 'people'">
                                            <img src="./assets/images/Process.svg"
                                                *ngIf="data.name == 'process'">
                                            <img src="./assets/images/technology.svg"
                                                *ngIf="data.name == 'technology'">
                                            <img src="./assets/images/Business_Excellence.svg"
                                                *ngIf="data.name == 'business Excellence'">
                                            <p class="mb-0">{{data.display_name}}</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mt-2">
                                            <div>
                                                <h4 class="cost">{{data.total_count}}</h4>
                                            </div>
                                            <div>
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">chevron_right
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ideas-partners">
                            <div class="dashboard-count-cards">
                                <span class="cards-bubble-top ml-4">&nbsp;</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center p-3 status-cards">
                                <div class="d-flex">
                                    <h5 class="fw-bold  ml-2 mt-2">{{staticText?.innovation.idea_page.invest_commit}}</h5>
                                </div>
                            </div>

                            <div class="row m-0 px-2 pt-2 pb-2 approved-ideas">
                                <div class="col cards-style indicators cursor-pointer"
                                    *ngFor="let data of dashboardItems?.delivery_partner; let i = index;"
                                    [ngClass]="{'active-benefits': vendor_id == data.id}">
                                    <div class="d-flex justify-content-between align-items-center mt-2"
                                        (click)="getInvestment(data.id)">
                                        <div class="d-flex align-items-center ">
                                            <span class="name-1 cursor-pointer"
                                                [ngStyle]="{'backgroundColor': i|getRandomColor}">
                                                {{getName(data?.name)}}
                                            </span>
                                            <p class="fw-medium mb-0 ml-2 vendor-name">{{data?.name}}</p>
                                        </div>
                                        <div>
                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                class="cursor-pointer fs-5">chevron_right
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 top-ideas px-1">
                        <div *ngIf="showPatnersList == false">
                            <span class="cards-bubble-top bg-4">
                                &nbsp;
                            </span>

                            <div class="dashboard-top-ideas mb-2">
                                <p class="m-0 idea-name p-3 fw-bold">{{staticText?.innovation.ideas}}</p>
                                <div class="top-ideas-section ">
                                    <div *ngFor="let items of topIdeas; let i = index">
                                        <div class="card card-wrapper mx-2 mb-2 rounded cursor-pointer"
                                            (click)="ideaDetails(items.id)">
                                            <div class="top-border" [ngClass]="{
                                          'red-border': items.status.label == 'Rejected', 
                                          'initiated-border': items.status.label == 'Approved', 
                                          'empty': items?.status == '',
                                          'green-border': items.status.label === 'Open' , 
                                          'partially-closed-border' : items.status.label == 'Under Review' 
                                       }">
                                            </div>
                                            <div>
                                                <div class="row header-wrapper cursor-pointer pt-2 px-2">
                                                    <div class="d-flex align-items-center col mx-2">
                                                        <div class="mr-1" *ngIf="items?.vendor_details == false">
                                                            <span class="name-1 cursor-pointer"
                                                                [ngStyle]="{'backgroundColor': i|getRandomColor}">
                                                                {{getName(items?.user_details)}}
                                                            </span>
                                                        </div>
                                                        <div class="mr-1" *ngIf="items?.vendor_details != false">
                                                            <span class="name-1 cursor-pointer"
                                                                [ngStyle]="{'backgroundColor': i|getRandomColor}">
                                                                {{getName(items?.vendor_details.primary_contact_details.username)}}
                                                            </span>
                                                        </div>
                                                        <div class="px-1">
                                                            <div *ngIf="items?.vendor_details == false">
                                                                <p
                                                                    class="text-dark fw-medium text-truncate card-title cursor-pointer mb-0">
                                                                    Delta {{items?.user_details}}
                                                                </p>
                                                            </div>
                                                            <div *ngIf="items?.vendor_details != false">
                                                                <p
                                                                    class="text-dark fw-bold text-truncate card-title cursor-pointer mb-0">
                                                                    {{items?.vendor_details?.primary_contact_details?.username}}
                                                                </p>
                                                            </div>

                                                            <small class="text-dark fs-12">{{items.created_on | date: 'd
                                                                MMM
                                                                y'}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 sub-heading">
                                                </div>
                                                <div class="innovation-inside-card">
                                                    <div class="last-wrapper">
                                                        <div class="col-md-12">
                                                            <div class="d-flex ">
                                                                <p class="text fw-bold idea-name mb-0">
                                                                    {{items.name}}
                                                                </p>
                                                            </div>
                                                            <div class="d-flex py-2">
                                                                <small class="benefits-details">
                                                                    {{items?.benefits_details?.display_name}}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify-content-between align-items-center px-3 py-2">
                                                    <div>
                                                        <div class="d-flex align-items-center">
                                                            <img src="./assets/images/people.svg"
                                                                *ngIf="items?.initiative_type_details.display_name == 'People'">
                                                            <img src="./assets/images/technology.svg"
                                                                *ngIf="items?.initiative_type_details.display_name == 'Technology'">
                                                            <img src="./assets/images/Business_Excellence.svg"
                                                                *ngIf="items?.initiative_type_details.display_name == 'Business Excellence'">
                                                            <img src="./assets/images/Process.svg"
                                                                *ngIf="items?.initiative_type_details.display_name == 'Process'">
                                                            <p class="p-0 text-nowrap mb-0"
                                                                [ngClass]="{'people-initiative': items?.initiative_type_details.display_name == 'People' ,  'technology-initiative ': items?.initiative_type_details.display_name == 'Technology' , 'process-initiative': items?.initiative_type_details.display_name == 'Process', 'business-initiative': items?.initiative_type_details.display_name == 'Business Excellence'  }">
                                                                {{items?.initiative_type_details.display_name}}</p>
                                                        </div>
                                                    </div>
                                                    <div class=" status-text p-0">
                                                        <p class="innovation-status mb-0" [ngClass]="{ 'red': items.status.label == 'Rejected' , 'empty': items.status.label == '' ,
                                    'initiated': items.status.label == 'Approved' ,  
                                    'green': items.status.label === 'Open' , 
                                    'partially-closed' : items.status.label == 'Under Review'}">
                                                            {{items.status.label}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="no-record" *ngIf="topIdeas?.length == 0">
                                    <span class="text-muted fw-bold">{{staticText.common.no_records_found}}</span>
                                </div>

                                <div class="d-flex justify-content-end px-3 pb-2 mt-4" *ngIf="topIdeas?.length > 2">
                                    <p class="ideaPage mb-0 cursor-pointer"
                                        (click)="navigateTo('innovation/list-idea')">{{staticText.common.see_all}}
                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                            class="cursor-pointer fs-5">chevron_right
                                        </mat-icon>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showPatnersList == true">
                            <span class="cards-bubble-top bg-4">
                                &nbsp;
                            </span>

                            <div class="dashboard-top-ideas">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="m-0 idea-name p-3 fw-bold">{{staticText?.innovation.idea_page.ric}}</p>
                                    </div>
                                    <div (click)="showEdit=!showEdit" class="cursor-pointer">
                                        <p class="m-0 label p-3 "><i class="fa fa-plus fw-0" aria-hidden="true"></i> {{staticText?.innovation.idea_page.add_ric}}</p>
                                    </div>
                                </div>

                                <div *ngIf="!showEdit" class="inner-scroll">
                                    <div *ngFor="let items of patnerList; let i = index">
                                        <div class="card card-wrapper mx-2 mb-2 rounded cursor-pointer">
                                            <div class="top-border" [ngStyle]="{'backgroundColor': i|getRandomColor}">
                                            </div>
                                            <div>
                                                <div class="row header-wrapper cursor-pointer pt-2 px-2">
                                                    <div
                                                        class="d-flex  justify-content-between align-items-center col mx-2">
                                                        <div class="p1-1 mb-3">
                                                            <div>
                                                                <p
                                                                    class="text-dark fw-medium  cursor-pointer investment-title mb-0">
                                                                    {{items.name }}
                                                                </p>
                                                            </div>
                                                            <small class="text-dark fs-12">{{items.created_on | date: 'd
                                                                MMM y'}}</small>
                                                        </div>
                                                        <div
                                                            class="d-flex align-items-center justify-content-between mb-4">
                                                            <div class="mx-2" (click)="showEdit=!showEdit">
                                                                <i class="fa fa-pencil-square-o" aria-hidden="true"
                                                                    (click)="editInvestment(items?.id)"></i>
                                                            </div>
                                                            <div>
                                                                <i class="fa fa-trash-o delete-icon"
                                                                    (click)="deleteInvestment(items?.id , delete )"
                                                                    aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 sub-heading">
                                                </div>
                                                <div class="innovation-inside-card">
                                                    <div class="last-wrapper">
                                                        <div class="col-md-12">
                                                            <div class="d-flex ">
                                                                <small class="mb-0 description investment-desc">
                                                                    {{items.description}}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex justify-content-between align-items-center px-3 py-2">
                                                    <div>
                                                        <div class="d-flex align-items-center">
                                                            <p class="p-0 text-nowrap m-0 label">
                                                                <i class="fa fa-usd mr-2"
                                                                    aria-hidden="true"></i>{{items.value}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="no-record" *ngIf="patnerList?.length == 0">
                                        <span class="text-muted fw-bold">{{staticText.common.no_records_found}}</span>
                                    </div>

                                </div>

                                <div class="p-3" *ngIf="showEdit">
                                    <p class="description fw-medium"> {{staticText?.innovation.idea_page.add_edit_ric}}</p>
                                    <hr>
                                    <form novalidate [formGroup]="addinvestmentForm">
                                        <div class="form-group mb-0 required">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>{{staticText?.innovation.idea_page.investment}}</mat-label>
                                                <input type="text" matInput formControlName="name"
                                                    placeholder="Enter an {{staticText?.innovation.idea_page.investment}} Name..." />
                                            </mat-form-field>
                                        </div>
                                        <div class="form-group mb-0 required">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>{{staticText?.innovation.idea_page.description}}</mat-label>
                                                <textarea matInput id="problem_stmt" formControlName="description"
                                                    cdkTextareaAutosize cdkAutosizeMinRows="4"
                                                    placeholder="Write {{staticText?.innovation.idea_page.description}} here..."></textarea>
                                            </mat-form-field>

                                        </div>
                                        <div class="form-group mb-0 required">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>{{staticText?.innovation.idea_page.value$}}</mat-label>
                                                <input type="text" formControlName="value" matInput />
                                            </mat-form-field>

                                        </div>

                                        <div class="d-flex">
                                            <div class="form-group">
                                                <button mat-button class="my-3 px-4 p-0 border border-default me-3"
                                                    (click)="cancel()">
                                                    <span class="m-0 btn-color">
                                                        {{staticText?.buttons.cancel_btn}}
                                                    </span>
                                                </button>
                                            </div>
                                            <div class="form-group">
                                                <button mat-button class="my-3 me-5 btn-bg text-white px-4 p-0"
                                                    (click)="submitInvestment()">
                                                    <span class="m-0">
                                                        {{staticText?.buttons.save_btn}}
                                                    </span>

                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="d-flex justify-content-end px-3 pb-2"
                                    *ngIf="topIdeas?.length > 2 && !showEdit && !showPatnersList">
                                    <p class="ideaPage mb-0 cursor-pointer"
                                        (click)="navigateTo('innovation/list-idea')">{{staticText?.common.see_all}}
                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                            class="cursor-pointer fs-5">chevron_right
                                        </mat-icon>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>-->
            </div>
        </div>
    </div>
</div>


<ng-template #delete>
    <div class="modal-dialog custom-width m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
            
            <div class="modal-body">
                <p class=" fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close (click)="closeDialog()" class="text-blue">{{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close (click)="deleteInvestmentbyId()"
                            class="text-white bg-red">{{staticText?.buttons?.delete_btn}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>