<div class="container-fluid container-xxl add-idea">
    <div class="row">
        <div class="col-md-9 px-4 center-section  mt-4">
            <div class="row ">
                <div class="col-md-12">
                    <div class="pt-4">
                        <div class="d-flex justify-content-between align-items-center cursor-pointer"
                            (click)="navigateTo('innovation/list-idea')">
                            <h2 class="screen-name text-truncate mb-0">
                                <i class="far fa-angle-left pr-2 "></i> {{ideaDetail?.name}}
                            </h2>
                            <span class="avatar fw-bold mr-2" *ngIf="ideaDetail?.created_by?.first_name != ''"
                                matTooltip="Created By: {{ideaDetail?.created_by?.first_name }}{{ideaDetail?.created_by?.last_name}}">{{getName(ideaDetail?.created_by?.first_name)}}{{getName(ideaDetail?.created_by?.last_name)}}
                            </span>
                            <span class="avatar fw-bold mr-2" *ngIf="ideaDetail?.created_by?.first_name == ''"
                                matTooltip="Created By: {{ideaDetail?.created_by?.username }}{{ideaDetail?.created_by?.username}}"
                                matTooltipClass="example-tooltip">{{getName(ideaDetail?.created_by?.username)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div>
                <div class="row section-1">
                    <div class="col-md-4 p-0 mb-2">
                        <div class="d-flex align-items-center col">
                            <div class="mr-1" *ngIf="ideaDetail?.vendor_details == false ">
                                <span class="name-1 cursor-pointer">

                                    {{getName(ideaDetail?.user_details)}}
                                </span>
                            </div>
                            <div class="mr-1" *ngIf="ideaDetail?.vendor_details != false">
                                <span class="name-1 cursor-pointer">

                                    {{getName(ideaDetail?.vendor_details.primary_contact_details.username)}}
                                </span>
                            </div>
                            <div>
                                <div *ngIf="ideaDetail?.vendor_details == false ">
                                    <p class="text-dark fw-medium text-truncate card-title cursor-pointer">
                                        Delta {{ideaDetail?.user_details}}
                                    </p>
                                </div>
                                <div *ngIf="ideaDetail?.vendor_details != false ">
                                    <p class="text-dark fw-medium text-truncate card-title cursor-pointer">
                                        {{ideaDetail?.vendor_details?.primary_contact_details?.username}}
                                    </p>
                                </div>

                                <small class="text-muted">Shared on {{ideaDetail?.created_on | date: 'd MMM y'}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>{{staticText?.innovation.add_idea.benefits}}</label>
                        <p class="benefits fw-bold">{{ideaDetail?.benefits_details?.display_name}}</p>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>{{staticText?.innovation.add_idea.benefits_value}}</label>
                        <p class="benefits-value">{{ideaDetail?.benefits_value}}</p>
                    </div>
                    <div class="col-md-2 mb-3">
                        <form [formGroup]="IdeaForm">
                            <label>{{staticText?.filter.status}}</label>
                            <mat-select class="selectbox-style w-100  px-2 " placeholder="SELECT"
                                [ngClass]="{'approved': ideaDetail?.status.label == 'Open' , 'progress': ideaDetail?.status.label == 'Approved' , 'under-review': ideaDetail?.status.label == 'Under Review',
                            'empty-status':  ideaDetail?.status.label !== 'Under Review' &&  ideaDetail?.status.label !== 'Open' , 'rejected': ideaDetail?.status.label == 'Rejected'}"
                                [(ngModel)]="selectedLevel" formControlName="status"
                                (selectionChange)="selectIdeaStatus($event )">
                                <mat-option *ngFor="let data of ideaStatus" [value]="data?.id">
                                    <span>{{data?.label | titlecase}}</span>
                                </mat-option>
                            </mat-select>
                        </form>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label>{{staticText?.filter.portfolio}}</label>
                        <p class="text-dark fw-bold details-style mb-0"
                            matTooltip="{{ideaDetail?.portfolio_details?.[0]?.display_name}}"
                            matTooltipClass="example-tooltip">
                            {{ideaDetail?.portfolio_details?.[0]?.display_name}}
                        </p>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>{{staticText?.filter.sub_portfolio}}</label>
                        <p class="text-dark fw-bold details-style mb-0"
                            matTooltip="{{ideaDetail?.portfolio_details?.[1]?.display_name}}"
                            matTooltipClass="example-tooltip">
                            {{ideaDetail?.portfolio_details?.[1]?.display_name}}</p>
                    </div>
                    <!-- <div class="col-md-3 mb-3" *ngIf="ideaDetail?.program_details">
                        <label>{{staticText?.filter.program}}</label>
                        <p class="text-dark fw-bold details-style mb-0"
                            matTooltip="{{ideaDetail?.program_details?.display_name}}"
                            matTooltipClass="example-tooltip">
                            {{ideaDetail?.program_details?.display_name}}
                        </p>
                    </div> -->
                    <div class="col-md-2 mb-3" *ngIf="ideaDetail?.project_details?.project_name">
                        <label>{{staticText?.filter.project}}</label>
                        <p class="text-dark fw-bold details-style mb-0"
                            matTooltip="{{ideaDetail?.project_details?.display_name}}"
                            matTooltipClass="example-tooltip">
                            {{ideaDetail?.project_details?.project_name}}
                        </p>
                    </div>

                    <div class="col-md-4 mb-3 ">
                        <label>{{staticText?.filter.initiative_type}}</label>
                        <p class="text-dark fw-bold details-style">{{ideaDetail?.initiative_type_details?.display_name}}
                        </p>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label>{{staticText?.innovation.add_idea.tags}}</label>
                        <div class="row">
                            <div class="col-md-auto p-0 " *ngFor="let data of ideaDetail?.tags">
                                <p class="tags-value text-nowrap text-truncate m-1" matTooltip="{{data}}"
                                    matTooltipClass="example-tooltip">
                                    {{data}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="ideaDetail?.program_details">
                        <label>{{staticText?.innovation.add_idea.drawndown}}</label>
                        <p class="text-dark fw-bold details-style mb-0" *ngIf="ideaDetail?.drawn_down_details?.value"
                            matTooltip="{{ideaDetail?.project_details?.display_name}}"
                            matTooltipClass="example-tooltip">
                            $ {{ideaDetail?.drawn_down_details?.value}}
                        </p>
                        <p class="text-dark fw-bold details-style mb-0" *ngIf="!ideaDetail?.drawn_down_details?.value">
                            $ N/A
                        </p>
                    </div>

                    <div class="col-md-3 " *ngIf="ideaDetail?.program_details">
                        <label>{{staticText?.innovation.add_idea.utilisation}}</label>
                        <p class="text-dark fw-bold details-style mb-0" *ngIf="ideaDetail?.utilisation">
                            {{ideaDetail?.utilisation}}
                        </p>
                        <p class="text-dark fw-bold details-style mb-0" *ngIf="!ideaDetail?.utilisation">
                            N/A
                        </p>
                    </div>
                </div>

                <div class="row">
                    <mat-label class="my-2 fw-bold">{{staticText?.innovation.add_idea.OKR}}</mat-label>
                    <mat-form-field class="example-chip-list" appearance="outline" class="elementalign">
                        <mat-label>{{staticText?.innovation.add_idea.select_okr}}</mat-label>
                        <mat-chip-list #chipList aria-label="tag selection">
                            <mat-chip *ngFor="let tag of okr" (removed)="remove(tag)">
                                {{tag}}
                                <!-- <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button> -->
                            </mat-chip>
                            <input placeholder="" #okrInput [formControl]="ocrCtrl" [matAutocomplete]="autoOKR"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                disabled="true">
                            <!-- <span matPrefix>
                                <mat-icon class="labelstyle" matPrefix>expand_more</mat-icon>
                            </span> -->
                        </mat-chip-list>

                        <mat-autocomplete #autoOKR="matAutocomplete">
                            <!-- <mat-option *ngFor="let tag of filteredOKR | async" [value]="tag">
                                {{tag.key_result_title}}
                            </mat-option> -->
                        </mat-autocomplete>
                    </mat-form-field>

                </div>
                <div class="row m-0 section-2">
                    <div class="problem-statement">
                        <span class="bubble-top">&nbsp;</span>
                        <label class="mt-4">{{staticText?.innovation.add_idea.problem_stmt}}</label>
                        <p>{{ideaDetail?.problem_statement}}</p>
                    </div>

                    <div class="solution-brief">
                        <span class="bubble-top">&nbsp;</span>
                        <label class="mt-4">{{staticText?.innovation.add_idea.solution_brief}}</label>
                        <p>{{ideaDetail?.solution_brief}}</p>
                    </div>

                    <div class="current-state">
                        <span class="bubble-top">&nbsp;</span>
                        <label class="mt-4">{{staticText?.innovation.add_idea.current_state}}</label>
                        <p>{{ideaDetail?.current_state}}</p>
                    </div>
                    <div class="benefits-description">
                        <span class="bubble-top">&nbsp;</span>
                        <label class="mt-4">{{staticText?.innovation.add_idea.benefits_desc}}</label>
                        <p>{{ideaDetail?.benefits_description}}</p>
                    </div>

                </div>

                <div class="row section-3 m-0">
                    <label class="document fw-bold p-0 mb-0">{{staticText?.innovation.add_idea.docs}}</label>
                    <div class="d-flex p-0">
                        <span class="file d-flex" *ngFor="let data of ideaDetail?.files">
                            <div class="d-flex" *ngIf="data?.file.includes('.doc') || data?.file.includes('.docx') ">
                                <img src="./assets/images/docs.png" class="img-pdf cursor-pointer"
                                    height="40">
                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                    {{data?.file}}</p>
                                <img src="./assets/images/download-icon-new.svg"
                                    class="img-pdf cursor-pointer" height="40" (click)="downloadFile(data?.file)">
                            </div>
                            <div class="d-flex" *ngIf="data?.file.includes('.ppt') || data?.file.includes('.pdf') ">
                                <img src="./assets/images/pdf-icon-new.svg" height="40"
                                    class="img-pdf cursor-pointer">
                                <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                    {{data?.file}}</p>
                                <img src="./assets/images/download-icon-new.svg"
                                    class="img-pdf cursor-pointer" height="40" (click)="downloadFile(data?.file)">
                            </div>
                        </span>
                        <br />
                    </div>

                    <div class="p-0" *ngIf="ideaDetail?.files.length == 0 || ideaDetail?.files.length == ''">
                        <p class="no-doc">{{staticText?.common.no_docs_found}} </p>

                    </div>
                </div>

                <div class="row section-4 m-0">
                    <div class="comments mb-4 p-0">
                        <label class="fw-bold text-dark mb-3">{{staticText?.action_tracker.comments}}</label>
                        <div class="d-flex mr-1">
                            <span
                                class="name-1 fw-bold mr-2">{{getName(user?.first_name)}}{{getName(user?.last_name)}}</span>
                            <form [formGroup]="commentsForm">
                                <div class="form-group position-relative">
                                    <img src="/assets/images/send.svg" (click)="SaveComments()">
                                    <input type="text" class="form-control comments-field cursor-pointer"
                                        id="exampleInputEmail1" formControlName="comment" aria-describedby="emailHelp"
                                        placeholder="Add comments...">
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-12 align-items-center col p-0"
                        *ngFor="let data of commentsList  | slice:0:!viewAllComments ? 3 : -1;index as commentIndex">
                        <div class=" row comments-list justify-content-between align-items-center">
                            <div class="col-md-11">
                                <div class="d-flex">
                                    <div class="mr-1">
                                        <span class="name-1 fw-bold mr-2"
                                            [ngStyle]="{'backgroundColor': commentIndex|getRandomColor}">{{getName(data.created_by.first_name)}}{{getName(data.created_by.last_name)}}</span>
                                    </div>
                                    <div>
                                        <div class="ml-2">
                                            <p class="text-dark fw-bold text-truncate card-title cursor-pointer mb-0">
                                                {{data?.created_by.first_name}} {{data?.created_by.last_name}}
                                            </p>
                                            <small class="text-muted">{{data?.last_updated_on | date: 'd MMM y , h:mm
                                                a'}}</small>
                                        </div>
                                        <div class="d-flex mt-2 ml-2">
                                            <p class="comments-style">{{data?.comment}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1" *ngIf="data?.flag">
                                <img class="cursor-pointer delete-icon"
                                    src="./assets/images/delete-icon-new.svg"
                                    (click)="deleteCommentPopup(deleteModal ,data.id )">
                            </div>
                        </div>
                    </div>

                    <div class="p-1 container-fluid mb-4" *ngIf="commentsList.length > 3 && !viewAllComments">
                        <div class="row row-cols-2">
                            <div class="col-8">
                                <div class="avatarList"
                                    *ngFor="let comments of commentsList | slice:3:20;index as commentIndex"
                                    [ngStyle]="{'transform': 'translate('+ 22 * commentIndex + 'px,-5px)'}">
                                    <ngx-avatar [name]="comments.created_by.username"
                                        [bgColor]="commentIndex | getRandomColor" size="38" [round]="true">
                                        <!--[bgColor]="getRandomcolors(commentIndex)" -->
                                    </ngx-avatar>
                                </div>
                            </div>
                            <div class="col-4">
                                <p class="h5 font-15 mb-0 text-capitalize link-color-blue cursor-pointer"
                                    (click)="viewAllComment()">
                                    {{commentsList.length - 3 +'+ others'}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <div class="mr-2">
                        <button mat-button class="my-3 px-4 p-0 border border-default btn-edit-bg" (click)="EditIdea()">
                            <span class="m-0">
                                {{staticText?.buttons.edit_btn}}
                            </span>
                        </button>
                    </div>

                    <div class="mr-2">
                        <button mat-button class="my-3 px-4 p-0  see-demo-btn">
                            <span class="m-0">
                                {{staticText?.innovation.idea_detail.book_demo}}
                            </span>
                        </button>
                    </div>
                    <button mat-button class="my-3 px-4 p-0 border border-default btn-bg"
                        (click)="navigateTo('innovation/list-idea')">
                        <span class="m-0">
                            {{staticText?.buttons.close_btn}}
                        </span>
                    </button>

                </div>
            </div>
        </div>
        <div class="col-md-3  mt-4">
            <div class="side-content-help">
                <div class="row right-panel-section">
                    <div class="col-md-12 col-lg-12">
                        <div class="header">
                            <p class="m-0 fw-bold">{{staticText?.innovation.idea_detail.similar_ideas}}</p>
                        </div>

                        <div class="top-idea align-items-center col p-0 bg-white px-2 py-4">
                            <div>
                                <div *ngFor="let items of similarIdeas; index as i">
                                    <div class="card card-wrapper mx-2 mb-2 rounded cursor-pointer"
                                        [ngClass]="{'active-Ideas': selectedSimilarIdeas == items.id}"
                                        (click)="getIdeaDeatilsById(items?.id)">
                                        <div class="top-border" [ngClass]="{
                                              'red-border': items.status.label == 'Rejected', 
                                              'initiated-border': items.status.label == 'Approved', 
                                              'empty': items?.status == '',
                                              'green-border': items.status.label === 'Open' , 
                                              'partially-closed-border' : items.status.label == 'Under Review' 
                                           }">
                                        </div>
                                        <div>
                                            <div class="row header-wrapper cursor-pointer pt-2">
                                                <div class="d-flex align-items-center col mx-2">
                                                    <div class="mr-1" *ngIf="items?.vendor_details == false ">
                                                        <span class="name-1 cursor-pointer"
                                                            [ngStyle]="{'backgroundColor': i|getRandomColor}">
                                                            <!--[ngStyle]="{'background': getRandomcolors(i)}"-->
                                                            {{getName(items?.user_details)}}
                                                        </span>
                                                    </div>
                                                    <div class="mr-1" *ngIf="items?.vendor_details != false">
                                                        <span class="name-1 cursor-pointer"
                                                            [ngStyle]="{'backgroundColor': i|getRandomColor}">
                                                            <!-- [ngStyle]="{'background': getRandomcolors(i)}"-->
                                                            {{getName(items?.vendor_details.primary_contact_details.username)}}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div *ngIf="items?.vendor_details == false">
                                                            <p
                                                                class="text-dark fw-bold text-truncate card-title cursor-pointer mb-0">
                                                                Delta {{items?.user_details}}
                                                            </p>
                                                        </div>
                                                        <div *ngIf="items?.vendor_details != false ">
                                                            <p
                                                                class="text-dark fw-bold text-truncate card-title cursor-pointer mb-0">
                                                                {{items?.vendor_details?.primary_contact_details?.username}}
                                                            </p>
                                                        </div>

                                                        <small class="text-muted fs-12">{{items.created_on | date: 'd
                                                            MMM
                                                            y'}}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 sub-heading">
                                            </div>
                                            <div class="innovation-inside-card">
                                                <div class="last-wrapper">
                                                    <div class="col-md-12">
                                                        <div class="d-flex ">
                                                            <p class="text fw-bold idea-name mb-0">
                                                                {{items.name}}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex py-2">
                                                            <small class="benefits-details">
                                                                {{items?.benefits_details?.display_name}}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center px-3">
                                                <div>
                                                    <div class="d-flex align-items-center">
                                                        <img src="./assets/images/people.svg"
                                                            *ngIf="items?.initiative_type_details.display_name == 'People'">
                                                        <img src="./assets/images/technology.svg"
                                                            *ngIf="items?.initiative_type_details.display_name == 'Technology'">
                                                        <img src="./assets/images/Business_Excellence.svg"
                                                            *ngIf="items?.initiative_type_details.display_name == 'Business Excellence'">
                                                        <img src="./assets/images/Process.svg"
                                                            *ngIf="items?.initiative_type_details.display_name == 'Process'">
                                                        <p class="p-0 text-nowrap mb-0"
                                                            [ngClass]="{'people-initiative': items?.initiative_type_details.display_name == 'People' ,  'technology-initiative ': items?.initiative_type_details.display_name == 'Technology' , 'process-initiative': items?.initiative_type_details.display_name == 'Process', 'business-initiative': items?.initiative_type_details.display_name == 'Business Excellence'  }">
                                                            {{items?.initiative_type_details.display_name}}</p>
                                                    </div>
                                                </div>
                                                <div class=" status-text p-0">
                                                    <p class="innovation-status mb-0" [ngClass]="{ 'red': items.status.label == 'Rejected' , 'empty': items.status.label == '' ,
                                        'initiated': items.status.label == 'Approved' ,  
                                        'green': items.status.label === 'Open' , 
                                        'partially-closed' : items.status.label == 'Under Review'}">
                                                        {{items.status.label}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>



    <ng-template #deleteModal>
        <div class="modal-dialog m-0">
            <div class="modal-content">
                <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
                
                <div class="modal-body">
                    <p class=" fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                    <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
                </div>
                <div class="modal-footer border-0">
                    <div class="row">
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close class="text-blue" (click)="closeDialog()">
                                {{staticText?.buttons?.cancel_btn}}</button>
                        </div>
                        <div class="col-md-6">
                            <button mat-button mat-dialog-close class="text-white bg-red"
                                (click)="deleteToeFile()">{{staticText?.buttons?.delete_btn}}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>