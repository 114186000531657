import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InnovationService } from 'src/app/shared/services/innovation.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, startWith } from 'rxjs/operators';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as textConfiguration from 'src/assets/static-text-configuration.json';

@Component({
  selector: 'app-idea-detail',
  templateUrl: './idea-detail.component.html',
  styleUrls: ['./idea-detail.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class IdeaDetailComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;

  currentRate = 8;
  selectedValue = 2;
  stars: number[] = [1, 2, 3, 4, 5];
  ideaDetailId: any;
  ideaDetail: any;
  initiative_type_id: any;
  similarIdeas: any;
  ideaStatus: any;
  public commentsForm = new FormGroup({});
  user_name: any;
  user: any;
  ColorRandom: any;
  IdeaForm: FormGroup | any;
  keyResultsData: any;
  ocrCtrl = new FormControl();
  filteredOKR: any;
  selectedOKR: any[] = []
  okr: string[] = [];
  @ViewChild('okrInput') okrInput: any | ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  okrList: any;
  selectedLevel: any;
  selectedId: any;
  selectedSimilarIdeas: any;
  viewAllComments: boolean = false;
  commentsList: any[] = [];
  commentIdtoDelete: any;


  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog, private fb: FormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService, private innovationService: InnovationService, private router: Router) { }

  ngOnInit(): void {
    this.getInnovationId();
    this.getIdeaStatus();
    this.initform();
    this.BuildForm();
    this.getKeyResultsData();
  }


  private BuildForm() {
    this.IdeaForm = this.fb.group({
      status: [],
    });
  }

  getInnovationId() {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.activatedRoute.params.subscribe(params => {
      this.ideaDetailId = atob(params.id);
      if (this.ideaDetailId != '') {
        this.getIdeaDeatilsById(this.ideaDetailId);
      }
    });
  }

  initform() {
    this.commentsForm = this.fb.group({
      comment: ["", [Validators.required]]
    });
  }

  SaveComments() {
    if (this.commentsForm.invalid) {
      return;
    }
    this.innovationService.updateComments(this.commentsForm.value, this.ideaDetailId).subscribe((response: any) => {
      if (response) {
        this.spinner.hide();
        this.getIdeaDeatilsById(this.ideaDetailId);
        this.commentsForm.reset();
        this.toastr.success("Comments Added Succesfully");
      }
      else {
        this.spinner.hide();
      }
    })
  }


  EditIdea() {
    this.router.navigate([`innovation/add-new-idea/${btoa(this.ideaDetailId)}`])
  }

  openOKRDeleteDialog(model: any, okr: any) {
    this.dialog.open(model);
  }
  deleteCommentPopup(model:any,commentId:any){
    this.commentIdtoDelete = commentId
    this.dialog.open(model);
  }
  closeDialog(){
    this.dialog.closeAll()
  }
  deleteToeFile() {
    this.innovationService.deleteCommands(this.commentIdtoDelete).subscribe((response: any) => {
      if (response) {
        this.spinner.hide();
        this.getIdeaDeatilsById(this.ideaDetailId);
        this.toastr.success("Comments Deleted Succesfully");
      }
      else {
        this.spinner.hide();
      }
    },err=>this.spinner.hide())

  }

  getIdeaDeatilsById(id: any) {
    this.selectedSimilarIdeas = id;
    this.spinner.show();
    this.okr = [];
    this.innovationService.getInnovationDetails(id).subscribe((response: any) => {
      if (response) {
        this.ideaDetail = response;
        this.commentsList = response?.comments
        this.initiative_type_id = response?.initiative_type;
        this.selectedValue = response?.rating;
        this.okrList = response?.key_results;
        this.spinner.hide();
        this.selectedLevel = response?.status?.id;
        this.getSimilarIdea(this.initiative_type_id);
        for (var i = 0; i < this.okrList.length; i++) {
          this.okr.push(this.okrList[i].key_result_title);
        }
      }
      else {
        this.spinner.hide();
      }
    },err=>this.spinner.hide())
  }

  getSimilarIdea(id: any) {
    this.spinner.show();
    this.innovationService.getSimilarIdeasByInitiative_id(id).subscribe((response: any) => {
      if (response) {
        this.similarIdeas = response?.records;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    },err=>this.spinner.hide())
  }
  getDetailsIdeas(id: any) {
    this.getIdeaDeatilsById(id);
  }

  selectIdeaStatus(event: any) {
    this.selectedId = event.value;
    var status_name = this.ideaStatus.find((x: any) => x.id === this.selectedId).slug;
    const data = {
      idea_id: this.ideaDetailId,
      status: status_name
    }
    this.innovationService.updateStatus(data).subscribe((response: any) => {
      if (response) {
        this.spinner.hide();
        this.getIdeaDeatilsById(this.ideaDetailId);
        this.toastr.success("Updated Successfully");
      }
      else {
        this.spinner.hide();
      }
    },err=>this.spinner.hide())
  }

  navigateTo(string: any) {
    this.router.navigate([string])
  }

  getRatingDetsails(id: any) {
    this.spinner.show();
    this.innovationService.getSimilarIdeasByInitiative_id(id).subscribe((response: any) => {
      if (response) {
        this.similarIdeas = response?.records;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    },err=>this.spinner.hide())
  }

  countStar(star: any, ideadId: any) {
    this.selectedValue = star;
    this.rating(star, ideadId);
  }


  rating(star: any, ideadId: any) {
    const rating = {
      idea_id: ideadId,
      rating: star
    }
    this.spinner.show();
    this.innovationService.getRating(rating).subscribe((response: any) => {
      if (response == true) {
        this.toastr.success("Rating Updated Successfully");
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    },err=>this.spinner.hide())
  }

  getIdeaStatus() {
    this.innovationService.getStatusFieldValues().subscribe((res: any) => {
      if (res) {
        this.ideaStatus = res;
      }
      else {
      }
    },err=>this.spinner.hide());
  }

  getName(name: any) {
    return name?.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }
  downloadFile(e: any) {
    var payload = {
      file_name: e,
    }
    this.spinner.show();
    this.innovationService.downloadIdeaFile(payload, this.ideaDetailId).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastr.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  getKeyResultsData() {
    this.innovationService.getKeyResultsFieldValues().subscribe((res: any) => {
      if (res) {
        this.keyResultsData = res;
        this.filteredOKR = this.ocrCtrl.valueChanges.pipe(
          startWith(null),
          map((okr: string | null) => (okr ? this._filterOKR(okr) : this.keyResultsData.slice())),
        );
      }
      else {
      }
    });
  }
  private _filterOKR(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.filteredOKR.filter((tag: any) => tag.toLowerCase().includes(filterValue));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.okr.push(event.option.viewValue);
    this.okrInput.nativeElement.value = '';
    this.ocrCtrl.setValue(null);
    this.selectedOKR.push({ uuid: event.option.value.uuid });
    this.updateOKR(this.selectedOKR);
  }

  updateOKR(data: any) {
    const payload = {
      idea_id: this.ideaDetailId,
      key_results: this.selectedOKR
    }
    this.innovationService.updateOKRData(payload).subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.toastr.success('Updated successfully');
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    },err=>this.spinner.hide());
    this.spinner.show();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.okr.push(value);
    }
    event.chipInput!.clear();
    this.ocrCtrl.setValue(null);
  }

  remove(okr: string): void {
    const index = this.okr.indexOf(okr);
    if (index >= 0) {
      this.okr.splice(index, 1);
      this.selectedOKR.splice(index, 1);
      this.updateOKR(this.selectedOKR);
    }
  }

  viewAllComment() {
    this.viewAllComments = true;
  }
}
