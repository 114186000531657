import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InnovationService } from 'src/app/shared/services/innovation.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-idea-page',
  templateUrl: './idea-page.component.html',
  styleUrls: ['./idea-page.component.scss'],
  encapsulation:ViewEncapsulation.Emulated

})
export class IdeaPageComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  user: any;
  initiativeData: any;
  OpportunityFieldList: any;
  portList: any;
  subPortfilteredOptions: any[] = [];
  benefitsData: any;
  initativeId: any;
  benefitsId: any;
  dashboardItems: any;
  similarIdeas: any;
  topIdeas: any;
  benefitsOnSelect: any;
  initiativeOnSelect: any;
  portFolioId: any;
  subPortId: any;
  //program: any;
  benefitName: any;
  statusId: any;
  initiativeTypes: any;
  ColorRandom: any;
  ColorRandomBackground: any;
  showPatnersList: boolean = false;
  showEdit: boolean = false;
  addinvestmentForm: FormGroup | any;
  vendor_id: any;
  patnerList: any;
  isEdit: boolean = false;
  investmentId: any;
  deleteInvestmentId: any;


  constructor(private router: Router, private dialog: MatDialog, private activatedRoute: ActivatedRoute, public fb: FormBuilder, private ps: ProgramService, private innovationService: InnovationService, private spinner: NgxSpinnerService, private toastrService: ToastrService,) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.createForm();
    this.getOpportunity();
    this.getInitiativeType();
    //this.getProgram();
    this.getOpportunity();
    this.getDashboardItems();
    this.getSimilarIdea();
    this.subPortfilteredOptions = [];
  }

  createForm() {
    this.addinvestmentForm = this.fb.group({
      name: [, { validators: [Validators.required, Validators.maxLength(100),] }],
      description: [, { validators: [Validators.required] }],
      value: [, {
        validators: [Validators.required,]
      }],
    });
  }




  getDashboardItems() {
    this.spinner.show();
    this.innovationService.getInnovationDashboard(0,10,this.subPortId, this.portFolioId, '', this.initativeId).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.dashboardItems = res;
        this.benefitName = this.dashboardItems?.benefits[0]?.name;        
        this.initiativeTypes = res?.initiative_types;
        if (this.dashboardItems.benefits[0]?.id != '' && this.dashboardItems.benefits[0]?.id != null && this.benefitsOnSelect != this.dashboardItems.benefits[0]?.id) {          
          this.benefitsOnSelect = this.dashboardItems?.benefits[0]?.id;
          this.showTopIdeas(this.benefitsOnSelect, this.benefitName);
        }        
      }
    });
    this.spinner.show();
  }

  addNewIdea() {
    this.router.navigateByUrl('innovation/add-new-idea');
  }

  getOpportunity() {
    this.ps.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList = res.records;
      this.OpportunityFieldList.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.portList = i.field_values;
        }
      })
    }, error => {
      this.toastrService.error('Opportunity fields get failed', 'Error');
      throw error;
    })
  }

  getInitiativeType() {
    this.innovationService.getInitiativeData().subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.initiativeData = res;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
    this.spinner.show();
  }
  clearFilter() {
    this.portFolioId = 0;
    this.subPortId = 0;
    //this.program = 0;
    this.initativeId = 0;
    this.getDashboardItems();
  }

  selectedInitiative(id: any) {
    this.initativeId = id;
    this.getDashboardItems();
  }
  // SelectedProgramValues(id: any) {
  //   this.program = id;
  //   this.getDashboardItems();

  // }

  selectedBenefits(id: any) {
    this.benefitsId = id;
    this.getDashboardItems();
  }
  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.portList.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }

  validateData(type: any) {
  }

  selectedPortfolioValues(id: any) {
    this.portFolioId = id;
    this.subPortId = "";
    let selectedPortfolio = this.portList.find((e:any)=> e.id === id);
    this.subPortfilteredOptions = selectedPortfolio?.sub_portfolio;
    if (this.portFolioId==='') {
      this.subPortfilteredOptions = [];
    }    
    this.getDashboardItems();
  }

  selectedSubportfolioValues(id: any) {
    this.subPortId = id;
    this.getDashboardItems();
  }

  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  changeSubPortfolio(e: any) {
    if (e.isUserInput) {
      this.subPortId = e.source.value.id;
    }
  }

  getSimilarIdea() {
    this.spinner.show();
    this.innovationService.getSimilarIdeas().subscribe((response: any) => {
      if (response) {
        this.similarIdeas = response?.records;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    })
  }

  navigateTo(link: string) {
    this.router.navigateByUrl("/" + link)
  }

  getName(name: any) {
    return name?.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }

  showTopIdeas(data: any, name: any) {
    this.showPatnersList = false;
    this.benefitsOnSelect = data;
    this.benefitName = name
    this.innovationService.getTopIdeas(data).subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.topIdeas = res?.idea_list;
        this.initiativeTypes = res?.initiative_types;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
    this.spinner.show();
  }
  showDashboardItemsByIdeas(data: any) {
    this.innovationService.getDashboardItems(data).subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.topIdeas = res?.idea_list;
        this.initiativeTypes = res?.initiative_types;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
    this.spinner.show();
  }
  ideasByInitiative(data: any) {
    this.initiativeOnSelect = data;
    this.innovationService.getTopIdeasInitiative(data).subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.topIdeas = res?.idea_list;
        console.log('this.topIdeas initiative', res);
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
    this.spinner.show();
  }

  navigateToList(id: any) {
    this.router.navigate([`innovation/list-idea/${btoa(id)}`])
  }


  ideaDetails(id: any) {
    this.router.navigate(['innovation/detail-ideas/' + btoa(id)])
  }

  getInvestment(id: any) {
    this.vendor_id = id;
    this.showPatnersList = true;
    this.innovationService.getDrawdownList(this.vendor_id).subscribe((response: any) => {
      if (response) {
        this.patnerList = response
        this.spinner.hide();
      }
    })
  }

  submitInvestment() {
    if (this.isEdit != true) {
      if (this.addinvestmentForm.valid) {
        this.spinner.show();
        const formdata = this.addinvestmentForm.value;
        this.innovationService.SaveInvestment(formdata, this.vendor_id).subscribe((res: any) => {
          if (res) {
            this.toastrService.success("Investment Added Successfully");
            this.showEdit = false;
            this.getInvestment(this.vendor_id);
            this.addinvestmentForm.reset();
            this.spinner.show();
          }
          this.spinner.hide();
        });
      }
      else {
        this.toastrService.warning("Please fill out this field ");
        return
      }



    }
    else {
      this.spinner.show();
      const formdata = this.addinvestmentForm.value;
      this.innovationService.updateInvestment(formdata, this.vendor_id, this.investmentId).subscribe((res: any) => {
        if (res) {
          this.getInvestment(this.vendor_id);
          this.toastrService.success("Investment Updated Successfully");
          this.showEdit = false;
          this.addinvestmentForm.reset();
          this.spinner.hide();

        }
        this.spinner.hide();
      });
    }
  }
  cancel() {
    this.getInvestment(this.vendor_id);
    this.showEdit = false;
    this.investmentId = ''
  }

  editInvestment(id: any) {
    this.isEdit = true;
    this.investmentId = id
    this.patnerList = this.patnerList.filter((item: any) => item.id == id);
    this.addinvestmentForm.patchValue(this.patnerList[0]);
  }

  deleteInvestmentbyId() {
    this.spinner.show();
    this.innovationService.deleteInvestment(this.deleteInvestmentId).subscribe((response: any) => {
      if (response) {
        this.toastrService.success('Deleted Successfully')
        this.getInvestment(this.vendor_id);
        this.spinner.hide();
      }
    })
  }

  deleteInvestment(id: any, content: any) {
    this.deleteInvestmentId = id;
    this.dialog.open(content)
  }
  closeDialog(){
    this.dialog.closeAll();
  }
}
