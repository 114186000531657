import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { InnovationService } from '../../../shared/services/innovation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { LodashService } from 'src/app/shared/services/lodash.service';

import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';

@Component({
  selector: 'app-add-new-idea',
  templateUrl: './add-new-idea.component.html',
  styleUrls: ['./add-new-idea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddNewIdeaComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  addideaForm: FormGroup | any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  ocrCtrl = new FormControl();
  tags: string[] = [];
  selectedOKR: any[] = []
  selectedTags: any[] = []
  okr: string[] = [];
  selectedFiles?: FileList;
  myFiles: string[] = [];
  fileList: any[] = [];
  @ViewChild('tagInput') tagInput: any | ElementRef<HTMLInputElement>;
  @ViewChild('okrInput') okrInput: any | ElementRef<HTMLInputElement>;
  submitted: any;
  initiativeData: any;
  benefitsData: any;
  keyResultsData: any;
  tagData: any;
  okrData: any
  filteredTags: any;
  filteredOKR: any
  UploadFiles: any[] = [];
  formvalues: any;
  deliveryProjectList: any;
  public ideaFormData: any = new FormData();
  files: any[] = [];
  cloneKeyResult: TreeviewItem[] = [];
  portFolioId: any;
  portfolio: any;
  subportfolio: any;
  filterPortfolioType: any;
  filterSubPortfolioType: any;
  filterProject: any;
  filterInitiative: any;
  filterBenefits: any;
  filterDrawdown: any;
  drawnList: any;
  ideaDetailId: any;
  okrList: any;
  tagList: any;
  editIdea: any;
  showtreeview: boolean = false;
  isEditPage: boolean = false;
  values: any[] = [];
  config: any = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500
  })
  keyData: any;
  okrTree: any[] = []
  items: TreeviewItem[] | any;
  okrSelectedList: any[] = [];
  okrpayloadUID: any[] = [];
  searchText: any
  activeNode: boolean = false;
  PatchTree: any[] = [];

  constructor(private activatedRoute: ActivatedRoute, private _formValidator: FormValidatorService, private router: Router,
    public fb: FormBuilder, private spinner: NgxSpinnerService, private toastrService: ToastrService, private innovationService: InnovationService,
    private lodashService: LodashService) {
  }

  ngOnInit(): void {
    this.createForm();
    this.getIdeaId();
    this.getTreeData();
    this.getInitiativeType();
    this.getBenefitsData();
    this.getKeyResultsData();
    //this.getTagData(); as discussed with Yasar commented this as of now
    this.getDeliveryProjectList();
    //this.getProgram();
    this.getOpportunity();
    this.getDrawdown();
  }

  createForm() {
    this.addideaForm = this.fb.group({
      name: [, { validators: [Validators.required, Validators.maxLength(100),] }],
      problem_statement: [, { validators: [Validators.required,] }],
      solution_brief: [, { validators: [Validators.required] }],
      current_state: [, { validators: [Validators.required] }],
      initiative_type: [, { validators: [Validators.required,this._formValidator.autocompleteObjectValidator()] }],
      portfolio: [, { validators: [Validators.required,this._formValidator.autocompleteObjectValidator()] }],
      sub_portfolio: [,[this._formValidator.autocompleteObjectValidator()]],
      //program: [,[this._formValidator.autocompleteObjectValidator()]],
      project: [,[this._formValidator.autocompleteObjectValidator()]],
      drawn_down: [,[this._formValidator.autocompleteObjectValidator()]],
      utilisation: [],
      benefits: [, { validators: [Validators.required,this._formValidator.autocompleteObjectValidator()] }],
      benefits_description: [, { validators: [Validators.required] }],
      benefits_value: [],
      tags: [],
      okr: [],
      documents: [],

    });

  }

  getIdeaId() {
    this.activatedRoute.params.subscribe(params => {
      this.ideaDetailId = params.id?atob(params.id):params.id;
      if (this.ideaDetailId != '' && this.ideaDetailId !== undefined) {
        this.isEditPage = true;
        this.getIdeaDeatilsById(this.ideaDetailId);
      }
      else {
        this.isEditPage = false;
      }
    });
  }

  getIdeaDeatilsById(id: any) {
    this.spinner.show();
    this.innovationService.getInnovationDetails(id).subscribe((response: any) => {
      if (response) {
        this.addideaForm.patchValue(response);
        this.files = response?.files;
        this.okrList = response?.key_results;
        this.addideaForm.patchValue({ 'portfolio': response?.portfolio_details });
        this.addideaForm.patchValue({ 'sub_portfolio': response?.sub_portfolio_details });
        //this.addideaForm.patchValue({ 'program': response?.program_details });
        this.addideaForm.patchValue({ 'project': response?.project_details });
        this.addideaForm.patchValue({ 'initiative_type': response?.initiative_type_details });
        this.addideaForm.patchValue({ 'benefits': response?.benefits_details });
        this.addideaForm.patchValue({ 'drawn_down': response?.drawn_down_details });
        this.PatchTree = response?.key_results;
        for (var i = 0; i < this.okrList.length; i++) {
          this.okr.push(this.okrList[i].key_result_title);
        }
        this.tagList = response?.tags;
        for (var i = 0; i < this.tagList?.length; i++) {
          this.tags.push(this.tagList[i]);
        }
      }
      else {
        this.spinner.hide();
      }
    })
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.okr.push(value);
    }
    event.chipInput!.clear();
    this.ocrCtrl.setValue(null);
  }

  remove(okr: string): void {
    const index = this.okr.indexOf(okr);
    if (index >= 0) {
      this.okr.splice(index, 1);
      this.selectedOKR.splice(index, 1);

    }
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tags.push(value);
    }
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
    this.selectedTags.push({ key_word: event.value });
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.selectedTags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.okr.push(event.option.viewValue);
    this.okrInput.nativeElement.value = '';
    this.ocrCtrl.setValue(null);
    this.selectedOKR.push({ uuid: event.option.value.uuid });
  }

  selectedTag(event: any): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.selectedTags.push({ key_word: event.option.value.slug });

  }

  selectedPortfolioValues(id: any) {
    this.portFolioId = id;    
    let selectedPortfolio = this.portfolio.find((e:any)=> e.id === id);
    this.subportfolio = selectedPortfolio?.sub_portfolio;
    if (this.portFolioId==="") {
      this.subportfolio = [];
      this.filterSubPortfolioType = this.addideaForm.get('sub_portfolio')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((name: any) => name ? this._filterSubPortfolio(name) : this.subportfolio.slice())
        );
    }
    this.addideaForm.get('sub_portfolio').patchValue('');
  }

  private _filter(value: any) {
    const filterValue = value.toLowerCase();
    return this.filteredTags.filter((tag: any) => tag?.display_name?.toLowerCase().includes(filterValue));
  }
  private _filterOKR(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.keyResultsData.filter((tag: any) => tag?.key_result_title?.toLowerCase().includes(filterValue));
  }
  private _filterPortfolio(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.portfolio.filter((tag: any) => tag?.display_name?.toLowerCase().includes(filterValue));
  }
  private _filterSubPortfolio(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.subportfolio.filter((tag: any) => tag?.display_name?.toLowerCase().includes(filterValue));
  }
  private _filterProjects(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.deliveryProjectList.filter((tag: any) => tag?.project_name?.toLowerCase().includes(filterValue));
  }
  private _filterInitiative(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.initiativeData.filter((tag: any) => tag?.display_name?.toLowerCase().includes(filterValue));
  }
  private _filterDrownDown(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.drawnList.filter((tag: any) => tag?.name?.toLowerCase().includes(filterValue));
  }
  _filterBenefits(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.benefitsData.filter((tag: any) => tag?.display_name?.toLowerCase().includes(filterValue));
  }
  public myError = (controlName: string, errorName: string) => {
    return this.addideaForm.controls[controlName].hasError(errorName);
  }

  private allowFreeTextAddEngineer = false;
  ideaListPage() {
    this.router.navigateByUrl('innovation/list-idea');
  }

  cancel() {
    this.router.navigateByUrl('innovation/list-idea');
  }
  getInitiativeType() {
    this.innovationService.getInitiativeData().subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.initiativeData = res;
        this.spinner.hide();
        this.filterInitiative = this.addideaForm.get('initiative_type')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.display_name),
            map((name: any) => name ? this._filterInitiative(name) : this.initiativeData.slice())
          );
      }
      else {
        this.spinner.hide();
      }
    });
  }

  getBenefitsData() {
    this.innovationService.getBenefitsFieldValue().subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.benefitsData = res;
        this.spinner.hide();
        this.filterBenefits = this.addideaForm.get('benefits')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.display_name),
            map((name: any) => name ? this._filterBenefits(name) : this.benefitsData.slice())
          );
      }
      else {
        this.spinner.hide();
      }
    });
  }
  displayName(data: any) {
    return data && data.key_result_title ? data.key_result_title : '';
  }

  getKeyResultsData() {
    this.innovationService.getKeyResultsFieldValues().subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.keyResultsData = res;
        this.filteredOKR = this.ocrCtrl!.valueChanges.pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.key_result_title),
          map((okr: string | null) => (okr ? this._filterOKR(okr) : this.keyResultsData.slice())),
        );
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
  }

  onSelectedChange(okrTags: any) {
    // below code for okr patch
    this.okrSelectedList = okrTags?.length > 0 ? okrTags : okrTags;
    this.okrpayloadUID = this.lodashService.Clonedata(okrTags)
    this.okrpayloadUID.forEach((v) => { delete v.title });
  }

  getTreeData() {
    this.innovationService.getKeydata().subscribe((response: any) => {
      if (response) {
        const keystructureData = response.records.find((x: any) => x.field_name == "opportunity_key_result");
        this.okrTree = keystructureData.field_values;
        this.constructTree(this.lodashService.Clonedata(keystructureData?.field_values));
        this.patchTreeValue(this.PatchTree);
      }
    })
  }

  deleteokr(e: any) {
    this.okrpayloadUID = this.okrpayloadUID.filter(item => item.uid !== e.uid);
  }

  activeNodeElement() {
    var treenode = document.getElementById('nestedTree');
    /*-------------Below treeNodeArray to find goal with help of ngx-treeview-item-------------------------*/
    var treeNodeArray: HTMLCollection | any = treenode?.getElementsByTagName('ngx-treeview-item');
    for (let nodeTree = 0; nodeTree < treeNodeArray?.length; nodeTree++) {
      /*-------------Below treeColorClass to find goal class to apply style-------------------------*/
      var treeColorClass = treeNodeArray[nodeTree].getElementsByClassName('form-inline');
      if (treeColorClass?.length > 0) {
        for (let formclass = 0; formclass < treeColorClass?.length; formclass++) {
          /*-------------Below parentNodeArray to find parent with help of ngx-treeview-item-------------------------*/
          let parentNodeArray = treeColorClass[formclass].nextElementSibling?.getElementsByTagName('ngx-treeview-item');
          if (parentNodeArray?.length > 0) {
            for (let parentNode = 0; parentNode < parentNodeArray?.length; parentNode++) {
              /*-------------Below parentColorClass to find parent class to apply style-------------------------*/
              var parentColorClass = parentNodeArray[parentNode].getElementsByClassName('form-inline');
              if (parentColorClass?.length > 1) {
                for (let parentFormclass = 0; parentFormclass < parentColorClass?.length; parentFormclass++) {
                  if (parentFormclass > 0) {
                    parentColorClass[parentFormclass]?.classList.add("active-Parent-state");
                    /*-------------Below childNodeArray to find child with help of ngx-treeview-item-------------------------*/
                    let childNodeArray = parentColorClass[parentFormclass]?.nextElementSibling?.getElementsByTagName('ngx-treeview-item');
                    if (childNodeArray?.length > 0) {
                      for (let childNodeIndex = 0; childNodeIndex < childNodeArray?.length; childNodeIndex++) {
                        /*-------------Below childColorClass to find child class to apply style-------------------------*/
                        var childColorClass = childNodeArray[childNodeIndex].getElementsByClassName('form-inline');
                        console.dir(childColorClass);
                        if (childColorClass?.length > 0) {
                          for (let childFormclass = 0; childFormclass < childColorClass?.length; childFormclass++) {
                            childColorClass[childFormclass]?.classList.add("active-Child-state");
                          }
                        }
                      }
                      break;
                    }
                  }
                }
                break;
              }
            }
            break;
          }
        }
        break;
      }
    }
  }

  patchTreeValue(data: any) {
    data.forEach((patchArray: any) => {
      this.cloneKeyResult?.forEach((DataPatch: any) => {
        DataPatch?.children?.find((findDataGoalPatch: any) => {
          if (findDataGoalPatch?.value?.uuid === patchArray?.strategic_goal_uuid) {
            findDataGoalPatch.internalChecked = true;
            findDataGoalPatch?.children?.find((findDataParentPatch: any) => {
              if (findDataParentPatch?.value?.uuid === patchArray?.parent_key_result_uuid) {
                findDataParentPatch.internalChecked = true;
                for (var findDataChildPatch of findDataParentPatch?.children) {
                  if (findDataChildPatch?.value?.uuid === patchArray?.key_result_uuid) {
                    findDataChildPatch.internalChecked = true;
                    this.okrSelectedList.push(findDataChildPatch?.value);
                  }
                }
              }
            })
          }
        })
      })
    })
  }

  constructTree(data: any) {
    let mapGoalData: any[] = [];
    if (data) {
      data?.forEach((mapGoal: any) => {
        let mapKeyResult: any[] = [];
        mapGoalData.push({
          text: mapGoal?.goal_title || '',
          value: { title: mapGoal?.goal_title, uuid: mapGoal?.goal_uuid } || '',
          children: mapKeyResult,
          collapsed: true,
          checked: false,
          disabled: false,
        })
        if (mapGoal?.key_results) {
          mapGoal?.key_results.forEach((keyresult: any) => {
            let mapChildKeyresult: any[] = []
            mapKeyResult.push({
              text: !!keyresult?.key_title === true ? keyresult?.key_title + ' ' + keyresult?.key_result_title : keyresult?.key_result_title || '',
              value: { title: !!keyresult?.key_title === true ? keyresult?.key_title + ' ' + keyresult?.key_result_title : keyresult?.key_result_title, uuid: keyresult?.key_result_uuid } || '',
              collapsed: true,
              checked: false,
              disabled: false,
              children: mapChildKeyresult
            })
            if (keyresult?.child_key_results) {
              keyresult?.child_key_results.forEach((childkeyresult: any) => {
                mapChildKeyresult.push({
                  text: !!childkeyresult?.key_title === true ? childkeyresult?.key_title + ' ' + childkeyresult?.key_result_title : childkeyresult?.key_result_title || '',
                  value: { title: !!childkeyresult?.key_title === true ? childkeyresult?.key_title + ' ' + childkeyresult?.key_result_title : childkeyresult?.key_result_title, uuid: childkeyresult?.key_result_uuid } || '',
                  collapsed: true,
                  checked: false,
                  disabled: false,
                })
              })
            }
          })
        }
      })
    }
    const goalTree = new TreeviewItem({
      text: 'All', value: 1, children: mapGoalData
    });
    return new Promise<boolean>((resolve, reject) => {
      this.cloneKeyResult = [goalTree];
      resolve(true);
    }).then((response: any) => {
      if (response) {
        this.loadTreeColors();
      }
    })
  }

  loadTreeColors() {
    setTimeout(() => {
      //set time out 1s delay coz dom should render in order to apply colors. 
      var treenode = document.getElementById('nestedTree');
      var treeNodeArray: HTMLCollection | any = treenode?.getElementsByTagName('ngx-treeview-item');
      for (let nodeTree = 0; nodeTree < treeNodeArray?.length; nodeTree++) {
        var treeColorClass = treeNodeArray[nodeTree].getElementsByClassName('form-inline');
        if (treeColorClass?.length > 0) {
          for (let formclass = 0; formclass < treeColorClass?.length; formclass++) {
            if (formclass !== 0) {
              treeColorClass[formclass].classList.add("active-Goal-state");
            } else {
              treeColorClass[formclass].classList.add("active-Tree-state");
            }
          }
          break;
        }
      }
    }, 2000);
  }

  getTagData() {
    this.innovationService.getTagFieldValues().subscribe((res: any) => {
      this.spinner.show();
      if (res) {
        this.tagData = res;
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
          startWith(null),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((tag: string | null) => (tag ? this._filter(tag) : this.tagData.slice())),
        );
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    });
  }

  submitIdea() {
    if (this.addideaForm.valid) {
      this.ideaFormData = new FormData()
      this.spinner.show();
      this.ideaFormData.append('name', this.addideaForm.value.name)
      if (this.addideaForm.value.problem_statement != undefined) {
        this.ideaFormData.append('problem_statement', this.addideaForm.value.problem_statement)
      }
      if (this.addideaForm.value.solution_brief != undefined) {
        this.ideaFormData.append('solution_brief', this.addideaForm.value.solution_brief)
      }
      if (this.addideaForm.value.current_state != undefined) {
        this.ideaFormData.append('current_state', this.addideaForm.value.current_state)
      }
      if (this.addideaForm.value.initiative_type?.id != undefined) {
        this.ideaFormData.append('initiative_type', this.addideaForm.value.initiative_type?.id)
      }
      if (this.addideaForm.value.initiative_type?.id != undefined) {
        this.ideaFormData.append('initiative_type', this.addideaForm.value.initiative_type?.id)
      }
      if (this.addideaForm.value.portfolio?.id != undefined) {
        this.ideaFormData.append('portfolio', this.addideaForm.value.portfolio?.id)
      }
      if (this.addideaForm.value.sub_portfolio?.id != undefined) {
        this.ideaFormData.append('sub_portfolio', this.addideaForm.value.sub_portfolio?.id)
      }
      // if (this.addideaForm.value.program?.id != undefined) {
      //   this.ideaFormData.append('program', this.addideaForm.value.program?.id)
      // }
      if (this.addideaForm.value.project?.id != undefined) {
        this.ideaFormData.append('project', this.addideaForm.value.project?.id)
      }
      if (this.addideaForm.value.benefits?.id != undefined) {
        this.ideaFormData.append('benefits', this.addideaForm.value.benefits?.id)
      }
      if (this.addideaForm.value.drawn_down?.id != undefined) {
        this.ideaFormData.append('drawn_down', this.addideaForm.value.drawn_down?.id)
      }
      if (this.addideaForm.value.utilisation != undefined) {
        this.ideaFormData.append('utilisation', this.addideaForm.value.utilisation)
      }else{
        this.ideaFormData.append('utilisation', '')
      }
      if (this.addideaForm.value.benefits_description != undefined) {
        this.ideaFormData.append('benefits_description', this.addideaForm.value.benefits_description)
      }
      if (this.addideaForm.value.benefits_value != undefined && this.addideaForm.value.benefits_value != null) {
        this.ideaFormData.append('benefits_value', this.addideaForm.value.benefits_value)
      }else{
        this.ideaFormData.append('benefits_value', '')
      }
      
      this.ideaFormData.append('key_results', JSON.stringify(this.okrpayloadUID))
      this.ideaFormData.append('tags', JSON.stringify(this.selectedTags) || 'test')
      if (this.isEditPage == false) {
        for (var i = 0; i < this.files.length; i++) {
          this.ideaFormData.append('documents', this.files[i]);
        }

        this.innovationService.submitIdeaForm(this.ideaFormData).subscribe((res: any) => {
          if (res) {
            this.toastrService.success("Ideas Saved Successfully");
            this.router.navigateByUrl('innovation/list-idea');
            this.addideaForm.reset();
            this.spinner.hide();
          }
          else {
            this.toastrService.warning("Please Enter mandatory Fields");
            this.spinner.hide();
          }
        }, err => {
          this.spinner.hide()
        });
      }
      else {
        this.innovationService.updateIdeaForm(this.ideaFormData, this.ideaDetailId).subscribe((res: any) => {
          if (res) {
            this.toastrService.success("Ideas Updated Successfully");
            this.router.navigateByUrl('innovation/list-idea');
            this.addideaForm.reset();
            this.spinner.hide();
          }
          else {
            this.toastrService.warning("Please Enter mandatory Fields");
            this.spinner.hide();
          }
        }, err => {
          this.spinner.hide()
        });
      }
    }
  }

  getOpportunity() {
    this.spinner.show();
    this.innovationService.getOpportunityFormData('new_opportunity').subscribe((response: any) => {
      if (response) {
        var portfolioList = response.records.find((x: any) => x.field_name == "opportunity_portfolio");
        this.portfolio = portfolioList.field_values;
        this.filterPortfolioType = this.addideaForm.get('portfolio')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.display_name),
            map((name: any) => name ? this._filterPortfolio(name) : this.portfolio.slice())
          );
        this.spinner.hide();
      }
    });
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }

  display_drawn_down_Fn(data: any) {
    return data && data.name ? data.name : '';
  }

  displayProFn(data: any) {
    return data && data.project_name ? data.project_name : '';
  }

  getDeliveryProjectList() {
    this.spinner.show();
    this.innovationService.projectList().subscribe((response: any) => {
      if (response) {
        this.deliveryProjectList = response;
        this.spinner.hide();
        this.filterProject = this.addideaForm.get('project')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.project_name),
            map((name: any) => name ? this._filterProjects(name) : this.deliveryProjectList.slice())
          );
      }
    })
  }
  getDrawdown() {
    this.spinner.show();
    this.innovationService.getDrawdown().subscribe((response: any) => {
      if (response) {
        this.drawnList = response;
        this.filterDrawdown = this.addideaForm.get('drawn_down')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this._filterDrownDown(name) : this.drawnList.slice())
          );
      }
      this.spinner.hide();
    })
  }

  onFileSelected(e: any) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.files.push(e.target.files[i]);
      this.fileList.push(e.target.files[i].name,);
    }
  }

  deleteFile(data: any) {
    this.files = this.files.filter(item => item.name !== data.name);
  }

}






