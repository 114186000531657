<div class="container-fluid add-idea container-xxl">
    <div class="row">
        <div class="col-md-9 px-4 center-section  mt-4">
            <div class="row border-bottom p-4">
                <div class="col-md-6">
                    <div>
                        <div class="cursor-pointer">
                            <h2 class="screen-name mb-0" (click)="ideaListPage()">
                                <i class="far fa-angle-left pr-2 "></i> {{staticText?.innovation.add_idea.new_idea}}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <hr> -->
            <div class="row pt-3">
                <form [formGroup]="addideaForm" novalidate>
                    <div class="scroll">
                        <div class="form-group required">
                            <mat-form-field class="elementalign" appearance="outline">
                                <mat-label>{{staticText?.innovation.add_idea.idea_name}}</mat-label>
                                <input type="text" matInput formControlName="name"
                                    placeholder="{{staticText?.innovation.add_idea.idea_type}}..." />
                            </mat-form-field>
                            <div class="form-control-feedback position-absolute mt-1"
                                *ngIf="addideaForm.controls.name.errors && addideaForm.controls.name.touched">

                                <p *ngIf="addideaForm.controls.name.errors.maxLength" class="text-danger ">
                                    {{staticText?.innovation.add_idea.max_limit}} </p>

                            </div>
                        </div>
                        <div class="form-group required">
                            <mat-form-field class="elementalign" appearance="outline">
                                <mat-label>{{staticText?.innovation.add_idea.problem_stmt}}</mat-label>
                                <textarea matInput formControlName="problem_statement" id="problem_stmt"
                                    cdkTextareaAutosize cdkAutosizeMinRows="4"
                                    placeholder="Write {{staticText?.innovation.add_idea.problem_stmt}} here..."></textarea>
                            </mat-form-field>
                            <mat-error *ngIf="submitted && addideaForm.controls.problem_statement.errors?.required">
                                {{staticText?.innovation.add_idea.prblm_stmt_err}} </mat-error>
                        </div>

                        <div class="form-group required">
                            <mat-form-field class="elementalign" appearance="outline">
                                <mat-label>{{staticText?.innovation.add_idea.solution_brief}}</mat-label>
                                <textarea formControlName="solution_brief" matInput id="solution_brief"
                                    cdkTextareaAutosize cdkAutosizeMinRows="4"
                                    placeholder="Write {{staticText?.innovation.add_idea.solution_brief}} here..."></textarea>
                            </mat-form-field>
                            <mat-error *ngIf="submitted && addideaForm.controls.solution_brief.errors?.required">
                                {{staticText?.innovation.add_idea.solution_brief_err}} </mat-error>
                        </div>

                        <div class="form-group required">
                            <mat-form-field class="elementalign" appearance="outline">
                                <mat-label>{{staticText?.innovation.add_idea.current_state}}</mat-label>
                                <textarea formControlName="current_state" matInput id="current_state"
                                    cdkTextareaAutosize cdkAutosizeMinRows="4"
                                    placeholder="Write {{staticText?.innovation.add_idea.current_state}} here..."></textarea>
                            </mat-form-field>
                            <mat-error *ngIf="submitted && addideaForm.controls.current_state.errors?.required">{{staticText?.innovation.add_idea.current_state_err}}</mat-error>
                        </div>

                        <div class="form-group ">
                            <div>
                                <mat-form-field class="example-chip-list" appearance="outline" class="elementalign">
                                    <mat-label>{{staticText?.innovation.add_idea.tags}}</mat-label>
                                    <mat-chip-list #chipTagList aria-label="tag selection">
                                        <mat-chip *ngFor="let tag of tags" (removed)="removeTag(tag)">
                                            {{tag}}
                                            <button matChipRemove>
                                            </button>
                                        </mat-chip>
                                        <input placeholder="" #tagInput [formControl]="tagCtrl"
                                            [matChipInputFor]="chipTagList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="addTag($event)" required>
                                        <span matPrefix>
                                        </span>
                                    </mat-chip-list>
                                </mat-form-field>
                                <mat-error *ngIf="submitted && addideaForm.controls.tags.errors?.required">{{staticText?.innovation.add_idea.tags_err}}
                                </mat-error>
                            </div>
                            <small class="text-muted">{{staticText?.innovation.add_idea.tag_note}}</small>
                        </div>
                        <div class="form-group">
                            <div class="d-flex">
                                <mat-form-field appearance="outline" class="required">
                                    <mat-label>{{staticText?.filter.portfolio}}</mat-label>
                                    <input matInput [matAutocomplete]="auto" type="text" formControlName="portfolio">
                                    <mat-icon matSuffix class="muted">search</mat-icon>
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                        <mat-option *ngFor="let option of filterPortfolioType | async" [value]="option"
                                            (onSelectionChange)="selectedPortfolioValues(option.id)">
                                            {{option.display_name| titlecase}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div class="required ml-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{staticText?.filter.sub_portfolio}}</mat-label>
                                        <input matInput [matAutocomplete]="auto1" type="text"
                                            formControlName="sub_portfolio">
                                        <mat-icon matSuffix class="muted">search</mat-icon>
                                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn">
                                            <mat-option *ngFor="let option of filterSubPortfolioType | async"
                                                [value]="option">
                                                {{option.display_name| titlecase}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <!-- <div class="ml-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{staticText?.filter.program}}</mat-label>
                                        <input matInput [matAutocomplete]="auto2" type="text" formControlName="program">
                                        <mat-icon matSuffix class="muted">search</mat-icon>
                                        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
                                            <mat-option *ngFor="let option of filterProgram | async" [value]="option">
                                                {{option.display_name| titlecase}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div> -->
                                <div class="ml-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{staticText?.innovation.add_idea.projects}}</mat-label>
                                        <input matInput [matAutocomplete]="auto3" type="text" formControlName="project">
                                        <mat-icon matSuffix class="muted">search</mat-icon>
                                        <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayProFn">
                                            <mat-option *ngFor="let option of filterProject | async" [value]="option">
                                                {{option.project_name| titlecase}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <div class="d-flex">
                                <div class="required ">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{staticText?.filter.initiative_type}}</mat-label>
                                        <input matInput [matAutocomplete]="auto4" type="text"
                                            formControlName="initiative_type">
                                        <mat-icon matSuffix class="muted">search</mat-icon>
                                        <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayFn">
                                            <mat-option *ngFor="let option of filterInitiative | async"
                                                [value]="option">
                                                {{option.display_name| titlecase}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="required ml-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{staticText?.innovation.add_idea.benefits}}</mat-label>
                                        <input matInput [matAutocomplete]="auto5" type="text"
                                            formControlName="benefits">
                                        <mat-icon matSuffix class="muted">search</mat-icon>
                                        <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayFn">
                                            <mat-option *ngFor="let option of filterBenefits | async" [value]="option">
                                                {{option.display_name| titlecase}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="form-group  mx-3">
                                    <mat-form-field class="elementalign w-100" appearance="outline">
                                        <mat-label>{{staticText?.innovation.add_idea.benefits_value}}</mat-label>
                                        <input type="number" class="benefits-value" matInput
                                            formControlName="benefits_value">
                                    </mat-form-field>

                                </div>
                                <div class="ml-2">
                                    <mat-form-field class="elementalign " appearance="outline">
                                        <mat-label>{{staticText?.innovation.add_idea.drawndown}}</mat-label>
                                        <input matInput [matAutocomplete]="auto6" type="text"
                                            formControlName="drawn_down">
                                        <mat-icon matSuffix class="muted">search</mat-icon>
                                        <mat-autocomplete #auto6="matAutocomplete"
                                            [displayWith]="display_drawn_down_Fn">
                                            <mat-option *ngFor="let option of filterDrawdown | async" [value]="option">
                                                {{option.name| titlecase}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="elementalign w-50" appearance="outline">
                                <mat-label>{{staticText?.innovation.add_idea.utilisation}}</mat-label>
                                <input type="text" matInput formControlName="utilisation">
                            </mat-form-field>
                        </div>

                        <div class="form-group required">
                            <mat-form-field class="elementalign" appearance="outline">
                                <mat-label>{{staticText?.innovation.add_idea.benefits_desc}}</mat-label>
                                <textarea formControlName="benefits_description" matInput id="benefits_description"
                                    cdkTextareaAutosize cdkAutosizeMinRows="1"
                                    placeholder="Enter an {{staticText?.innovation.add_idea.benefits_desc}}..."></textarea>
                            </mat-form-field>
                            <mat-error *ngIf="submitted && addideaForm.controls.benefits_description.errors?.required">
                                {{staticText?.innovation.add_idea.benefits_desc_err}}</mat-error>
                        </div>
                        <div class="form-group required okr">
                            <div>
                                <!-- <p *ngIf="files.length == 0" class="pt-3 mb-0 fw-bold add-okr cursor-pointer"
                                    (click)="showtreeview=!showtreeview"> <i class="fal fa-plus fw-bold"></i> Add
                                    OKR</p> -->
                                <button class="addOkr text-white text-capitalize cursor-pointer fw-bold fs-16"
                                    mat-raised-button (click)="showtreeview=true;loadTreeColors()">
                                    &plus;&nbsp;{{staticText?.innovation?.add_idea?.add_okr}}
                                </button>
                                <!-- <mat-form-field class="example-chip-list" appearance="outline" class="elementalign"
                                    (click)="showtreeview=!showtreeview">
                                    <mat-label>Select OKR</mat-label>
                                    <mat-chip-list #chipList aria-label="tag selection">
                                        <mat-chip *ngFor="let data of okr" (removed)="remove(data)">
                                            {{data}}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <input placeholder="" #okrInput [formControl]="ocrCtrl"
                                            [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="add($event)" required>
                                        <span matPrefix>
                                            <mat-icon matSuffix class="muted">search</mat-icon>                                            
                                        </span>
                                    </mat-chip-list>
                                      <mat-autocomplete #autoOKR="matAutocomplete" (optionSelected)="selected($event)"
                                        [displayWith]="displayName">
                                        <mat-option *ngFor="let data of filteredOKR | async" [value]="data">
                                            {{data.key_result_title}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <mat-error *ngIf="submitted && addideaForm.controls.okr.errors?.required">OKR is
                                    required
                                </mat-error> -->
                            </div>
                        </div>


                        <div class="row okr-list" *ngIf="okrSelectedList?.length > 0">

                            <div class="col-md-auto" *ngFor="let data of okrSelectedList">
                                <p class="chips"> {{data.title}}</p>
                            </div>
                        </div>
                        <div class="mb-1">
                            <label for="benefits" class="labelstyle">{{staticText?.innovation.add_idea.docs}}</label><br>
                            <small class="text-muted">
                                {{staticText?.innovation.add_idea.content}}</small>
                        </div>
                        <div class="d-flex form-group">
                            <div class="col-md-3 p-0">
                                <div class="upload mt-2 ">
                                    <div class="w-100 cursor-pointer d-flex justify-content-center mb-2"
                                        (click)="fileInput1.click()">
                                        <small class="p-2 fw-bold"> {{staticText?.common.upload_doc}}</small>
                                    </div>
                                    <input multiple hidden (change)="onFileSelected($event)" #fileInput1 type="file"
                                        name="file" id="file" accept=".doc, .pdf, .docx, .ppt, .pptx">
                                    <small class="doc text-nowrap pt-2">
                                        {{staticText?.action_tracker.doc_maxsize}}</small>
                                </div>
                            </div>

                            <div class="col-md-9">
                                <div>
                                    <div class="d-flex" style="overflow: scroll;">
                                        <p *ngIf="files.length == 0" class="pt-3 mb-0 fw-bold add-doc cursor-pointer"
                                            (click)="fileInput1.click()"> <i class="fal fa-plus fw-bold"></i> {{staticText?.action_tracker.add_doc}}</p>

                                        <span *ngFor="let data of files" class="file d-flex">
                                            <div *ngIf="data?.name">
                                                <div class="d-flex"
                                                    *ngIf="data?.name?.includes('.doc') || data?.name?.includes('.docx') ">
                                                    <img src="./assets/images/docs.png" height="40">
                                                    <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                        {{data.name}}</p>
                                                    <img src="./assets/images/delete-icon-new.svg"
                                                        height="40" (click)="deleteFile(data)">
                                                </div>
                                                <div class="d-flex"
                                                    *ngIf="data.name.includes('.ppt') || data.name.includes('.pdf') ">
                                                    <img src="./assets/images/pdf-icon-new.svg"
                                                        height="40">
                                                    <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                        {{data.name}}</p>
                                                    <img src="./assets/images/delete-icon-new.svg"
                                                        height="40" (click)="deleteFile(data)">
                                                </div>
                                            </div>

                                            <div *ngIf="data?.file">
                                                <div class="d-flex"
                                                    *ngIf="data?.file?.includes('.doc') || data?.file?.includes('.docx') ">
                                                    <img src="./assets/images/docs.png" height="40">
                                                    <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                        {{data.file}}</p>
                                                    <img src="./assets/images/delete-icon-new.svg"
                                                        height="40" (click)="deleteFile(data)">
                                                </div>
                                                <div class="d-flex"
                                                    *ngIf="data.file.includes('.ppt') || data.file.includes('.pdf') ">
                                                    <img src="./assets/images/pdf-icon-new.svg"
                                                        height="40">
                                                    <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                        {{data.file}}</p>
                                                    <img src="./assets/images/delete-icon-new.svg"
                                                        height="40" (click)="deleteFile(data)">
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div class="float-right">
                            <button mat-button (click)="cancel()" class="my-3 px-4 p-0 border border-default me-3">
                                <span class="m-0">
                                    {{staticText?.buttons.cancel_btn}} 
                                </span>
                            </button>
                            <button mat-button class="my-3 me-5 btn-bg text-white px-4 p-0" (click)="submitIdea()">
                                <span class="m-0">
                                    {{staticText?.innovation.add_idea.save_idea}}
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-md-3 mt-4">
            <div class="d-flex flex-column tree" *ngIf="showtreeview">
                <div class="d-flex flex-row justify-content-between align-items-center border-bottom p-2 pb-3">
                    <p class="add-okr-title mb-0">{{staticText?.innovation.add_idea.add_okr}}</p>
                    <div class="cursor-pointer" (click)="showtreeview=false">
                        <img src="./assets/images/Group 8814.svg">
                    </div>
                </div>
                <div class="pt-3" id="nestedTree">
                    <ngx-treeview [config]="config" [items]="cloneKeyResult" (click)="activeNodeElement()"
                        (selectedChange)="onSelectedChange($event)">
                    </ngx-treeview>
                </div>
            </div>
            <div *ngIf="!showtreeview">
                <div class="side-content-help">
                    <div class="row">
                        <app-list [type]="'help'"></app-list>
                    </div>
                </div>
                <div class="col-md-auto">
                    <app-ticket></app-ticket>
                </div>
            </div>
        </div>
    </div>
</div>