import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InnovationRoutingModule } from './innovation-routing.module';
import { IdeaPageComponent } from './idea-page/idea-page.component';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { AddNewIdeaComponent } from './add-new-idea/add-new-idea.component';
import { ListIdeaComponent } from './list-idea/list-idea.component';
import { IdeaDetailComponent } from './idea-detail/idea-detail.component';
import { TreeviewModule } from 'ngx-treeview';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    IdeaPageComponent,
    IndexComponent,
    AddNewIdeaComponent,
    ListIdeaComponent,
    IdeaDetailComponent
  ],
  imports: [
    CommonModule,
    InnovationRoutingModule,
    SharedModule,
    MatIconModule,
    FormsModule, ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxDatatableModule,
    MatMenuModule,
    MatButtonModule,
    MatOptionModule,
    NgxPaginationModule,
    MaterialmodulesModule,
    TreeviewModule.forRoot(),
    NgxPermissionsModule
  ]
})
export class InnovationModule { }
